import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { AlertSeverity } from '../types/common/alert';
import { v4 as uuidV4 } from 'uuid';

const useStyles = makeStyles(() => ({
  snackbarContent: {
    '& p': {
      margin: 0,
    },
    '& a': {
      color: 'inherit',
    },
  },
}));

const handleOnClick = (event: React.MouseEvent) => {
  // if the user clicked the link inside the alert we retry the operation by reloading the page
  if ((event.target as HTMLElement)?.tagName === 'A') {
    const a = event.target as HTMLLinkElement;
    // check to see if link is empty (same url as current location)
    if (a.href === window.location.origin + window.location.pathname) {
      event.preventDefault();
      window.location.reload();
    }
  }
};

export function useAlert() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const enqueueAlert = useCallback(
    ({
      id = uuidV4(),
      messageKey,
      severity = AlertSeverity.ERROR,
      duration,
    }: {
      id?: string;
      messageKey: string;
      severity?: AlertSeverity;
      duration?: number;
    }) => {
      enqueueSnackbar(<ReactMarkdown children={t(messageKey)!} className={classes.snackbarContent} />, {
        autoHideDuration: duration ?? severity === AlertSeverity.SUCCESS ? 3000 : null,
        key: id,
        variant: severity,
        onClick: (event) => {
          handleOnClick(event);
          closeSnackbar(id);
        },
      });

      return id;
    },
    [classes, t, enqueueSnackbar, closeSnackbar],
  );

  return { enqueueAlert, closeAlert: closeSnackbar };
}
