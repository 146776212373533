import React, { useEffect } from 'react';
import logo from '../../../logo.svg';
import { Trans, useTranslation } from 'react-i18next';
import useFormBuilder, { onlyPhone } from '../../../hooks/useFormBuilder';
import { useParams } from 'react-router-dom';
import { RegisterForm } from '../../../types/patient/forms';
import { useAlert } from '../../../hooks/useAlerts';
import { apiEndpoints, useSubmitRequest } from '../../../hooks/useSubmitRequest';
import { FormBackdrop, FormButtons, FormRemark, FormTitle, useFormStyles } from '../../../components/form';
import { Link, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  logo: {
    maxWidth: 120,
    alignSelf: 'center',
  },
  done: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

const accessTokenLength = 32;

export default function SignUp() {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const { t } = useTranslation();
  const { accessToken } = useParams<{ accessToken: string }>();
  const {
    TextField,
    Checkbox,
    handleSubmit,
    reset,
    formState: { isSubmitting, isSubmitSuccessful, isSubmitted, isValid, submitCount },
  } = useFormBuilder('patient.SignUp.form');
  const submitRequest = useSubmitRequest();
  const onSubmit = ({ termsAndConditions: _termsAndConditions, ...data }: RegisterForm) =>
    submitRequest(apiEndpoints.patient.register({ accessToken }), data, 1000);
  const { enqueueAlert } = useAlert();

  useEffect(() => {
    if (accessToken?.length !== accessTokenLength) enqueueAlert({ messageKey: 'patient.SignUp.invalidUrl' });
  }, [accessToken, enqueueAlert]);

  useEffect(() => {
    if (!isSubmitSuccessful && isSubmitted && isValid) enqueueAlert({ messageKey: 'patient.SignUp.failed' });
  }, [submitCount, enqueueAlert]);

  if (accessToken?.length !== accessTokenLength) {
    return null;
  }

  return (
    <div className={formClasses.main}>
      <img className={classes.logo} src={logo} alt="logo" />

      {isSubmitSuccessful ? (
        <div className={classes.done}>
          <FormTitle titleKey={'patient.SignUp.done'} />
          <div>{t('patient.SignUp.backToApp')}</div>
        </div>
      ) : (
        <>
          <form id="signUp" className={formClasses.form} onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormTitle titleKey={'patient.SignUp.title'} />

            <TextField fieldName="firstName" required />
            <TextField fieldName="lastName" required />
            <TextField fieldName="birthDate" type="date" required InputLabelProps={{ shrink: true }} />
            <TextField fieldName="phone" required pattern={onlyPhone} />
            <TextField fieldName="postalCode" required />
            <TextField fieldName="city" required />
            <TextField fieldName="street" required />
            <Checkbox
              fieldName="termsAndConditions"
              required
              label={
                <Trans
                  i18nKey="patient.SignUp.form.pleaseAcceptTermsAndConditionsText"
                  components={{
                    link1: <Link href="/register/termsAndConditions" target="_blank" />,
                  }}
                />
              }
            />

            <FormButtons
              form={'signUp'}
              reset={reset}
              isSubmitting={isSubmitting}
              resetKey={'patient.SignUp.reset'}
              submitKey={'patient.SignUp.submit'}
            />
            <FormRemark remarkKey={'patient.SignUp.remark'} />
          </form>

          <FormBackdrop isSubmitting={isSubmitting} />
        </>
      )}
    </div>
  );
}
