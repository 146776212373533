import { Responsibility, Role } from '../../../types/admin/form';
import { useHistory } from 'react-router-dom';
import { TableCell, TableRow } from '@material-ui/core';
import { translateRole } from '../../../translations/translate';

export default function ResponsibilityRow({
  responsibility,
  nightShift,
}: {
  responsibility: Responsibility;
  nightShift: boolean;
}) {
  const { push } = useHistory();
  const disabled = responsibility.role === Role.DOCTOR;
  const formatDate = (date: string | null) => (date ? date.slice(0, -3) : '---');

  const values: string[] = [
    translateRole(responsibility.role),
    formatDate(nightShift ? responsibility.dayShiftEnds : responsibility.dayShiftStarts),
    formatDate(nightShift ? responsibility.dayShiftStarts : responsibility.dayShiftEnds),
    (nightShift ? responsibility.sharedPhoneNight : responsibility.sharedPhoneDay) ?? '---',
  ];
  return (
    <TableRow
      key={responsibility.id}
      onClick={disabled ? undefined : () => push(`/admin/responsibility/${responsibility.id}`)}>
      {values.map((value, index) => (
        <TableCell key={`${responsibility.id} ${index}`}>{value}</TableCell>
      ))}
    </TableRow>
  );
}
