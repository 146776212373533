import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Responsibility, Role } from '../../../types/admin/form';
import { apiEndpoints, useSubmitRequest } from '../../../hooks/useSubmitRequest';
import { useAlert } from '../../../hooks/useAlerts';
import { useListStyles } from '../../../components/list/baseStyles';
import { makeStyles } from '@material-ui/core';
import { FormBackdrop } from '../../../components/form';
import ResponsibilitiesTable from './ResponsibilitiesTable';

const filterAfterRole = (responsibilities: Responsibility[], role: Role) =>
  responsibilities.filter((responsibility) => responsibility.role === role);

const matchResponseChain = (responsibilities: Responsibility[]) =>
  filterAfterRole(responsibilities, Role.PSYCHOLOGIST).concat(
    filterAfterRole(responsibilities, Role.DOCTOR),
    filterAfterRole(responsibilities, Role.CHIEF_DOCTOR),
  );

export const useStyles = makeStyles((theme) => ({
  spaceBefore: {
    marginTop: theme.spacing(6),
  },
}));

export default function ResponsibilityList() {
  const { t } = useTranslation();
  const [responsibilities, setResponsibilities] = useState<Responsibility[]>();
  const { enqueueAlert } = useAlert();
  const submitRequest = useSubmitRequest();
  const baseClasses = useListStyles();
  const classes = useStyles();

  useEffect(() => {
    submitRequest<{ items: Responsibility[] }>(apiEndpoints.admin.responsibility.list, undefined)
      .then(({ items }) => setResponsibilities(matchResponseChain(items)))
      .catch(() => enqueueAlert({ messageKey: `admin.Responsibility.list.failedLoading` }));
  }, []);

  if (!responsibilities) {
    return <FormBackdrop isSubmitting />;
  }

  return (
    <div className={baseClasses.root}>
      <h1 className={baseClasses.title}>{t('admin.Responsibility.list.title')}</h1>
      <h2>{t('admin.Responsibility.list.day')}</h2>
      <ResponsibilitiesTable responsibilities={responsibilities} nightShift={false} />
      <h2 className={classes.spaceBefore}>{t('admin.Responsibility.list.night')}</h2>
      <ResponsibilitiesTable
        responsibilities={responsibilities.filter((responsibility) => responsibility.role !== Role.PSYCHOLOGIST)}
        nightShift={true}
      />
    </div>
  );
}
