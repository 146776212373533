import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useAlert } from '../../../hooks/useAlerts';
import { apiEndpoints, useSubmitRequest } from '../../../hooks/useSubmitRequest';
import { FormBackdrop, FormButtons, FormRemark, FormTitle, useFormStyles } from '../../../components/form';
import useFormBuilder, { onlyPhone } from '../../../hooks/useFormBuilder';
import { AlertSeverity } from '../../../types/common/alert';
import { Responsibility, Role } from '../../../types/admin/form';

export default function ResponsibilityUpdate() {
  const { responsibilityId } = useParams<{ responsibilityId: string }>();
  const [role, setRole] = useState<Role | undefined>(undefined);
  const { enqueueAlert } = useAlert();
  const submitRequest = useSubmitRequest();

  const formClasses = useFormStyles();

  const {
    TextField,
    handleSubmit,
    reset,
    formState: { isSubmitting, isSubmitSuccessful, isSubmitted, isValid, submitCount },
  } = useFormBuilder('admin.Responsibility.update');

  useEffect(() => {
    submitRequest<Responsibility>(apiEndpoints.admin.responsibility.read({ responsibilityId }), undefined)
      .then(({ id, ...state }) => {
        reset(state);
        setRole(state.role);
      })
      .catch(() => enqueueAlert({ messageKey: `admin.Responsibility.update.failedLoading` }));
  }, [responsibilityId]);

  const onUpdate = (data: FormData) =>
    submitRequest(apiEndpoints.admin.responsibility.update({ responsibilityId }), data, 1000).then(() =>
      enqueueAlert({ messageKey: `admin.Responsibility.update.success`, severity: AlertSeverity.SUCCESS }),
    );

  useEffect(() => {
    if (!isSubmitSuccessful && isSubmitted && isValid)
      enqueueAlert({ messageKey: `admin.Responsibility.update.failed` });
  }, [submitCount, enqueueAlert]);

  if (!role) {
    return <FormBackdrop isSubmitting />;
  }

  return (
    <div className={formClasses.main}>
      <form id="ResponsibilityUpdate" className={formClasses.form} onSubmit={handleSubmit(onUpdate)} noValidate>
        <FormTitle titleKey={`admin.Responsibility.update.title`} />
        <TextField fieldName="dayShiftStarts" type="time" required />
        <TextField
          fieldName="dayShiftEnds"
          required
          type="time"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300,
          }}
        />
        {role === Role.CHIEF_DOCTOR && (
          <>
            <TextField fieldName="sharedPhoneDay" pattern={onlyPhone} />
            <TextField fieldName="sharedPhoneNight" pattern={onlyPhone} />
          </>
        )}
        <FormButtons
          form={'ResponsibilityUpdate'}
          isSubmitting={isSubmitting}
          submitKey={`admin.Responsibility.update.submit`}
        />
        <FormRemark remarkKey={`admin.Responsibility.update.remark`} />
      </form>
      <FormBackdrop isSubmitting={isSubmitting} />
    </div>
  );
}
