import React from 'react';
import Router from './router/Router';
import AuthProvider from './contexts/auth/AuthProvider';

export default function App() {
  return (
    <AuthProvider>
      <Router />
    </AuthProvider>
  );
}
