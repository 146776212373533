import React, { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox/Checkbox';

// TODO: fix typings
export default function useFormBuilder(baseKey: string, defaultValues?: any) {
  const { control, formState, ...rest } = useForm({ ...(defaultValues && { defaultValues: defaultValues }) });
  const { t, i18n } = useTranslation();

  const Field = useCallback(
    ({
      fieldName,
      required = false,
      pattern,
      ...props
    }: { fieldName: string; pattern?: RegExp } & Omit<TextFieldProps, 'value' | 'label' | 'error' | 'helperText'>) => (
      <Controller
        name={fieldName}
        control={control}
        rules={{ required, pattern }}
        render={({ field: { ref, value, ...rest }, fieldState: { error } }) => (
          <TextField
            {...props}
            {...rest}
            inputRef={ref}
            value={value ?? ''}
            helperText={
              error
                ? t(`${baseKey}.${fieldName}_Error`)
                : i18n.exists(`${baseKey}.${fieldName}_Help`) && t(`${baseKey}.${fieldName}_Help`)
            }
            error={!!error}
            label={t(`${baseKey}.${fieldName}`)}
            required={required}
            key={fieldName}
          />
        )}
      />
    ),
    [control],
  );

  const CheckboxField = useCallback(
    ({
      fieldName,
      required = true,
      label,
      ...props
    }: { fieldName: string; label: ReactNode } & Omit<CheckboxProps, 'error' | 'helperText'>) => (
      <Controller
        name={fieldName}
        control={control}
        rules={{ required }}
        render={({ field: { ref, value, ...rest }, fieldState: { error } }) => (
          <FormControl error={!!error}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox {...props} {...rest} checked={value ?? false} inputRef={ref} color="primary" />}
                label={label}
              />
            </FormGroup>
            {error && <FormHelperText>{t(`${baseKey}.${fieldName}_Error`)}</FormHelperText>}
          </FormControl>
        )}
      />
    ),
    [control],
  );

  return { TextField: Field, Checkbox: CheckboxField, formState, control, ...rest };
}

export const onlyLetters = /^[A-Za-z]+$/i;
export const onlyLettersAndNumbers = /^[A-Za-z0-9]+$/i;
export const onlyZip = /^[1-9][0-9]{3}$/i;
export const onlyPhone = /^\+41[0-9 ]*$/i;
