import { Responsibility } from '../../../types/admin/form';
import { useTranslation } from 'react-i18next';
import { useListStyles } from '../../../components/list/baseStyles';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import ResponsibilityRow from './ResponsibilityRow';

export default function ResponsibilitiesTable({
  responsibilities,
  nightShift,
}: {
  responsibilities: Responsibility[];
  nightShift: boolean;
}) {
  const { t } = useTranslation();
  const classes = useListStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {['role', 'shiftStarts', 'shiftEnds', 'sharedPhone'].map((value) => (
              <TableCell key={value}>{t(`admin.Responsibility.list.${value}`)}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {responsibilities.map((responsibility: Responsibility) => (
            <ResponsibilityRow responsibility={responsibility} nightShift={nightShift} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
