import { getApiClientForMethod, Method } from '../common/api/request-builder';
import { getEndpoints } from '../common/api/api';

const delay = (delayMs = 0) => new Promise((resolve) => setTimeout(resolve, delayMs));

const axiosConfig = { withCredentials: true };

const apiCall = async <FormBody>({ method, url }: { url: string; method: Method }, body: FormBody) => {
  try {
    return await getApiClientForMethod(method)(url, ...(body ? [body, axiosConfig] : [axiosConfig]));
  } catch {
    return { status: -1 } as const;
  }
};

export function useSubmitRequest() {
  // const { logout } = useAuth();
  // const { enqueueAlert } = useAlert();

  return async <Result = void, Body = void>(
    endpoint: { url: string; method: Method },
    body: Body,
    delayMs?: number,
  ): Promise<Result> => {
    const [result] = await Promise.all([apiCall(endpoint, body), delay(delayMs)]);

    if (result.status === -1) {
      // TODO: better way
      // logout();
      // enqueueAlert({ messageKey: `admin.Login.expired` });
    }

    if (result.status !== 200) {
      return Promise.reject('Invalid API Call'); // sentry ignores this specific error
    }

    return result.data;
  };
}

export const apiEndpoints = getEndpoints(process.env.REACT_APP_ENDPOINT as string);
