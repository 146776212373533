import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiEndpoints, useSubmitRequest } from '../../../hooks/useSubmitRequest';
import { OrganizationUser, Role, UserState } from '../../../types/admin/form';
import { FormBackdrop } from '../../../components/form';
import { useHistory } from 'react-router-dom';
import { useAlert } from '../../../hooks/useAlerts';
import { translateGender, translateRole, translateUserState } from '../../../translations/translate';
import { Gender } from '../../../types/common/form';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useListStyles } from '../../../components/list/baseStyles';

const organizationUserAttributes = [
  'firstName',
  'lastName',
  'threemaId',
  'phone',
  'email',
  'gender',
  'role',
  'userState',
] as const;
const translateOrganizationUserAttribute = (user: OrganizationUser, attribute: keyof OrganizationUser) => {
  const value = user[attribute];
  switch (attribute) {
    case 'gender':
      return translateGender(value as Gender);
    case 'role':
      return translateRole(value as Role);
    case 'userState':
      return translateUserState(value as UserState);
    default:
      return value;
  }
};

export default function OrganizationUsersList() {
  const classes = useListStyles();
  const { t } = useTranslation();
  const [organizationUsers, setOrganizationUsers] = useState<OrganizationUser[]>();
  const { push } = useHistory();
  const { enqueueAlert } = useAlert();
  const submitRequest = useSubmitRequest();

  useEffect(() => {
    submitRequest<{ items: OrganizationUser[] }>(apiEndpoints.admin.organizationUser.list, undefined)
      .then(({ items }) => setOrganizationUsers(items))
      .catch(() => enqueueAlert({ messageKey: `admin.OrganizationUser.list.failedLoading` }));
  }, []);

  if (!organizationUsers) {
    return <FormBackdrop isSubmitting />;
  }

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>{t('admin.OrganizationUser.list.title')}</h1>

      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {organizationUserAttributes.map((attribute) => (
                <TableCell key={attribute}>{t(`admin.OrganizationUser.form.${attribute}`)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {organizationUsers.map((user) => (
              <TableRow key={user.id} onClick={() => push(`/admin/organizationUser/${user.id}`)}>
                {organizationUserAttributes.map((attribute) => (
                  <TableCell key={attribute}>{translateOrganizationUserAttribute(user, attribute)}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
