import React, { useEffect } from 'react';
import useFormBuilder, { onlyPhone } from '../../../hooks/useFormBuilder';
import { useAlert } from '../../../hooks/useAlerts';
import { OrganizationUserForm as FormData, Role, UserState } from '../../../types/admin/form';
import { Gender } from '../../../types/common/form';
import { menuItems } from '../../../common/utility/form';
import { translateGender, translateRole, translateUserState } from '../../../translations/translate';
import { FormBackdrop, FormButtons, FormRemark, FormTitle, useFormStyles } from '../../../components/form';
import { omit } from 'ramda';

export default function OrganizationUserForm({
  onSubmit,
  formKey,
  initialState,
  updateForm = false,
}: {
  onSubmit: (data: FormData) => unknown;
  initialState?: Partial<FormData>;
  formKey: string;
  updateForm?: boolean;
}) {
  const formClasses = useFormStyles();
  const {
    TextField,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, isSubmitted, isValid, submitCount },
  } = useFormBuilder('admin.OrganizationUser.form', initialState);
  const { enqueueAlert } = useAlert();

  useEffect(() => {
    if (!isSubmitSuccessful && isSubmitted && isValid)
      enqueueAlert({ messageKey: `admin.OrganizationUser.${formKey}.failed` });
  }, [submitCount, enqueueAlert]);

  return (
    <div className={formClasses.main}>
      <form id="organizationUserCreate" className={formClasses.form} onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormTitle titleKey={`admin.OrganizationUser.${formKey}.title`} />
        <TextField fieldName="firstName" required />
        <TextField fieldName="lastName" required />
        <TextField
          fieldName="threemaId"
          required
          inputProps={{ readOnly: updateForm }}
          className={updateForm ? formClasses.readOnly : ''}
        />
        <TextField fieldName="phone" required pattern={onlyPhone} />
        <TextField fieldName="email" required />
        <TextField fieldName="gender" required select defaultValue={Gender.OTHER}>
          {menuItems(Gender, translateGender)}
        </TextField>
        <TextField fieldName="role" required select defaultValue={Role.DOCTOR}>
          {menuItems(omit([Role.CHIEF_DOCTOR], Role), translateRole)}
        </TextField>
        {updateForm && (
          <TextField fieldName="userState" required select>
            {menuItems(UserState, translateUserState)}
          </TextField>
        )}
        <FormButtons
          form={'organizationUserCreate'}
          isSubmitting={isSubmitting}
          submitKey={`admin.OrganizationUser.${formKey}.submit`}
        />
        <FormRemark remarkKey={`admin.OrganizationUser.${formKey}.remark`} />
      </form>

      <FormBackdrop isSubmitting={isSubmitting} />
    </div>
  );
}
