import { Box, Link, makeStyles, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import React from 'react';
import { FormTitle } from '../../../components/form';

export const useStyles = makeStyles((theme) => ({
  typographyBody1Bold: {
    fontWeight: 600,
  },
}));

export default function TermsAndConditions() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box sx={{ px: 2 }}>
      <FormTitle titleKey={`patient.SignUp.termsAndConditionsPage.title`} />
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5">{t('patient.SignUp.termsAndConditionsPage.whatIsItSubtitle')}</Typography>
        <Typography variant="body1">{t('patient.SignUp.termsAndConditionsPage.whatIsItBody')}</Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5">{t('patient.SignUp.termsAndConditionsPage.whySubtitle')}</Typography>
        <Typography variant="body1">{t('patient.SignUp.termsAndConditionsPage.whyBody')}</Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5">{t('patient.SignUp.termsAndConditionsPage.forWhoSubtitle')}</Typography>
        <Typography variant="body1">{t('patient.SignUp.termsAndConditionsPage.forWhoBody')}</Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5">{t('patient.SignUp.termsAndConditionsPage.whoChatsSubtitle')}</Typography>
        <Typography variant="body1">{t('patient.SignUp.termsAndConditionsPage.whoChatsBody')}</Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5">{t('patient.SignUp.termsAndConditionsPage.howToGetOnItSubtitle')}</Typography>
        <Typography variant="body1">{t('patient.SignUp.termsAndConditionsPage.howToGetOnItBody')}</Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5">{t('patient.SignUp.termsAndConditionsPage.howDoesItWorkSubtitle')}</Typography>
        <Typography variant="body1">{t('patient.SignUp.termsAndConditionsPage.howDoesItWorkBody')}</Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5">{t('patient.SignUp.termsAndConditionsPage.whatDoesItCostSubtitle')}</Typography>
        <Typography variant="body1">{t('patient.SignUp.termsAndConditionsPage.whatDoesItCostBody')}</Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5">{t('patient.SignUp.termsAndConditionsPage.isItSecureSubtitle')}</Typography>
        <Typography variant="body1">
          <Trans
            i18nKey="patient.SignUp.termsAndConditionsPage.isItSecureBody"
            components={{
              threemaLink: <Link href="https://www.threema.ch" target="_blank" rel="noopener noreferrer" />,
            }}
          />
        </Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5">{t('patient.SignUp.termsAndConditionsPage.isItAlwaysReachableSubtitle')}</Typography>
        <Typography variant="body1">
          <Trans
            i18nKey="patient.SignUp.termsAndConditionsPage.isItAlwaysReachableBody"
            components={{
              bold: <Typography variant="body1" display="inline" className={classes.typographyBody1Bold} />,
            }}
          />
        </Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5">{t('patient.SignUp.termsAndConditionsPage.parentsSubtitle')}</Typography>
        <Typography variant="body1">{t('patient.SignUp.termsAndConditionsPage.parentsBody')}</Typography>
      </Box>
      <Box sx={{ mb: 6 }}>
        <Typography variant="h5">{t('patient.SignUp.termsAndConditionsPage.whatElseSubtitle')}</Typography>
        <Typography variant="body1">
          <Trans
            i18nKey="patient.SignUp.termsAndConditionsPage.whatElseBody"
            components={{
              threemaLink: <Link href="https://www.threema.ch" target="_blank" rel="noopener noreferrer" />,
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
}
