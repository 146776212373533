import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  buttons: {
    alignSelf: 'flex-start',

    '& > button': {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
}));

export default function FormButtons({
  form,
  reset,
  isSubmitting,
  resetKey,
  submitKey,
}: {
  form: string;
  reset?: () => unknown;
  isSubmitting: boolean;
  resetKey?: string;
  submitKey: string;
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.buttons}>
      {reset && resetKey && (
        <Button color="primary" onClick={() => reset()}>
          {t(resetKey)}
        </Button>
      )}

      <Button form={form} type="submit" variant="contained" color="primary" formNoValidate disabled={isSubmitting}>
        {t(submitKey)}
      </Button>
    </div>
  );
}
