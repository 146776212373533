import React from 'react';
import { Route, RouteProps, Router as ReactRouter, Switch } from 'react-router-dom';
import { useStyles } from './styles';
import { routerHistory } from './routerHistory';
import SignUp from '../screens/patient/SignUp/SignUp';
import { SnackbarProvider } from 'notistack';
import theme from '../styling/theme';
import OrganizationUserCreate from '../screens/admin/organizationUser/OrganizationUserCreate';
import OrganizationUserUpdate from '../screens/admin/organizationUser/OrganizationUserUpdate';
import OrganizationUsersList from '../screens/admin/organizationUser/OrganizationUsersList';
import Navigation from '../components/navigation/Navigation';
import { useAuth } from '../hooks/useAuth';
import Login from '../screens/admin/login/Login';
import { CssBaseline, StylesProvider, ThemeProvider, Typography } from '@material-ui/core';
import ResponsibilityList from '../screens/admin/responsibility/ResponsibilityList';
import ResponsibilityUpdate from '../screens/admin/responsibility/ResponsibilityUpdate';
import TermsAndConditions from '../screens/patient/SignUp/TermsAndConditions';

type RouteInformation = [path: string, component: RouteProps['component']];

const publicRoutes: RouteInformation[] = [
  ['/register/termsAndConditions', TermsAndConditions],
  ['/register/:accessToken', SignUp],
  ['/login', Login],
];

const adminRoutes: RouteInformation[] = [
  ['/admin/organizationUser/:organizationUserId', OrganizationUserUpdate],
  ['/admin/organizationUser', OrganizationUserCreate],
  ['/admin/organizationUsers', OrganizationUsersList],
  ['/admin/responsibilities', ResponsibilityList],
  ['/admin/responsibility/:responsibilityId', ResponsibilityUpdate],
];

export default function Router() {
  const classes = useStyles();
  const { isAuthenticated } = useAuth();

  const routes = isAuthenticated ? [...publicRoutes, ...adminRoutes] : publicRoutes;

  return (
    <ReactRouter history={routerHistory}>
      <StylesProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Typography component="div">
            <SnackbarProvider maxSnack={5} preventDuplicate anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
              <div className={classes.root}>
                {isAuthenticated && <Navigation />}
                <main className={classes.main}>
                  <Switch>
                    {routes.map(([path, component], key) => (
                      <Route {...{ path, component, key }} />
                    ))}
                    <Route path="*" component={isAuthenticated ? OrganizationUsersList : Login} />
                  </Switch>
                </main>
              </div>
            </SnackbarProvider>
          </Typography>
        </ThemeProvider>
      </StylesProvider>
    </ReactRouter>
  );
}
