import { makeStyles } from '@material-ui/core';

export const useFormStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: 500,
    maxWidth: `calc(100vw - ${theme.spacing(4)}px)`,

    '& > div': {
      marginTop: theme.spacing(1),
    },
  },
  readOnly: {
    opacity: 0.38,
  },
}));
