import { createTheme } from '@material-ui/core';

export const theme = createTheme({
  shape: {
    borderRadius: 0,
  },
  palette: {
    primary: {
      contrastText: '#fff',
      main: 'rgb(237,66,57)',
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#1976d2',
        },
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottomColor: '#1976d2',
        },
      },
    },
  },
});

export default theme;
