import { apiEndpoints, useSubmitRequest } from '../../../hooks/useSubmitRequest';
import { OrganizationUserForm as FormData, Role } from '../../../types/admin/form';
import OrganizationUserForm from './OrganizationUserForm';
import { Gender } from '../../../types/common/form';
import { useHistory } from 'react-router-dom';
import { useAlert } from '../../../hooks/useAlerts';
import { AlertSeverity } from '../../../types/common/alert';

export default function OrganizationUserCreate() {
  const { push } = useHistory();
  const { enqueueAlert } = useAlert();
  const submitRequest = useSubmitRequest();

  const onSubmit = (data: FormData) =>
    submitRequest(apiEndpoints.admin.organizationUser.create, data, 1000).then(() => {
      enqueueAlert({ messageKey: `admin.OrganizationUser.create.success`, severity: AlertSeverity.SUCCESS });
      push('/');
    });

  return (
    <OrganizationUserForm
      onSubmit={onSubmit}
      formKey={'create'}
      initialState={{
        gender: Gender.OTHER,
        role: Role.DOCTOR,
      }}
    />
  );
}
