import { apiEndpoints, useSubmitRequest } from '../../../hooks/useSubmitRequest';
import { OrganizationUser, OrganizationUserForm as FormData } from '../../../types/admin/form';
import OrganizationUserForm from './OrganizationUserForm';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FormBackdrop } from '../../../components/form';
import { useAlert } from '../../../hooks/useAlerts';
import { AlertSeverity } from '../../../types/common/alert';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

export default function OrganizationUserUpdate() {
  const { organizationUserId } = useParams<{ organizationUserId: string }>();
  const [initialState, setInitialState] = useState<FormData>();
  const { enqueueAlert } = useAlert();
  const { push } = useHistory();
  const { t } = useTranslation();
  const submitRequest = useSubmitRequest();

  const onUpdate = (data: FormData) =>
    submitRequest(apiEndpoints.admin.organizationUser.update({ organizationUserId }), data, 1000).then(() =>
      enqueueAlert({ messageKey: `admin.OrganizationUser.update.success`, severity: AlertSeverity.SUCCESS }),
    );

  const onDelete = () =>
    submitRequest(apiEndpoints.admin.organizationUser.delete({ organizationUserId }), undefined)
      .then(() => {
        enqueueAlert({ messageKey: `admin.OrganizationUser.delete.success`, severity: AlertSeverity.SUCCESS });
        push('/');
      })
      .catch(() => enqueueAlert({ messageKey: `admin.OrganizationUser.delete.failed` }));

  useEffect(() => {
    submitRequest<OrganizationUser>(apiEndpoints.admin.organizationUser.read({ organizationUserId }), undefined)
      .then(({ id, ...state }) => setInitialState(state))
      .catch(() => enqueueAlert({ messageKey: `admin.OrganizationUser.update.failedLoading` }));
  }, [organizationUserId]);

  if (!initialState) {
    return <FormBackdrop isSubmitting />;
  }

  return (
    <>
      <OrganizationUserForm onSubmit={onUpdate} initialState={initialState} formKey={'update'} updateForm />
      <Alert
        severity="error"
        action={
          <Button color="inherit" size="small" onClick={onDelete}>
            {t('admin.OrganizationUser.delete.submit')}
          </Button>
        }>
        <AlertTitle>{t('admin.OrganizationUser.delete.submit')}</AlertTitle>
        {t('admin.OrganizationUser.delete.really')}
      </Alert>
    </>
  );
}
