import { Gender } from '../common/form';

export enum Role {
  DOCTOR = 'DOCTOR',
  CHIEF_DOCTOR = 'CHIEF_DOCTOR',
  PSYCHOLOGIST = 'PSYCHOLOGIST',
}

export enum UserState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type Responsibility = Readonly<{
  id: string;
  role: Role;
  dayShiftStarts: string | null;
  dayShiftEnds: string | null;
  sharedPhoneDay: string | null;
  sharedPhoneNight: string | null;
}>;

export type ResponsibilityForm = Omit<Responsibility, 'id'>;

export type OrganizationUser = Readonly<{
  id: string;
  firstName: string;
  lastName: string;
  threemaId: string;
  phone: string;
  email: string;
  gender: Gender;
  role: Role;
  userState: UserState;
}>;

export type OrganizationUserForm = Omit<OrganizationUser, 'id'>;
