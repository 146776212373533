import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const useStyles = makeStyles((theme) => ({
  title: {
    alignSelf: 'flex-start',
    color: theme.palette.primary.main,
  },
}));

export default function FormTitle({ titleKey }: { titleKey: string }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return <h1 className={classes.title}>{t(titleKey)}</h1>;
}
