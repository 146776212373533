import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from './de.json';

export const setupTranslations = () =>
  i18n.use(initReactI18next).init({
    resources: {
      de,
    },
    lng: 'de',
    interpolation: {
      escapeValue: false,
    },
    parseMissingKeyHandler: (key) => {
      console.error(key);
      return key;
    },
  });
