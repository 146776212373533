import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { environment } from './common/utility/environment';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { setupTranslations } from './translations/setup';

Sentry.init({
  dsn: environment === 'localhost' ? '' : 'https://28fcce96e483483c9ce15dc933f0fc03@sentry.healthinal.com/7',
  autoSessionTracking: false,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.5,
  environment,
  release: process.env.REACT_APP_FRONTEND_VERSION,
  ignoreErrors: [
    'Uncaught (in promise) Invalid API Call', //Ignored because of: https://github.com/react-hook-form/react-hook-form/pull/5391
  ],
});

setupTranslations().then(() =>
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
  ),
);
