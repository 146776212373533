import React from 'react';
import { MenuItem } from '@material-ui/core';

export function menuItems<T extends string>(fieldValues: { [key: string]: T }, display: (value: T) => string) {
  return Object.values(fieldValues).map((value, key) => (
    <MenuItem key={key} value={value} data-test-value={value} data-option-index={key}>
      {display(value)}
    </MenuItem>
  ));
}
