import { pipe } from 'ramda';
import { getRequestBuilder } from './request-builder';

export const getEndpoints = pipe(getRequestBuilder, (requestBuilder) => ({
  login: requestBuilder.post('login'),
  patient: {
    register: (urlParams: { accessToken: string }) => requestBuilder.post('register/{accessToken}', urlParams),
  },
  admin: {
    organizationUser: {
      create: requestBuilder.post('admin/organizationUser'),
      read: (urlParams: { organizationUserId: string }) =>
        requestBuilder.get('admin/organizationUser/{organizationUserId}', urlParams),
      list: requestBuilder.get('admin/organizationUsers/'),
      update: (urlParams: { organizationUserId: string }) =>
        requestBuilder.put('admin/organizationUser/{organizationUserId}', urlParams),
      delete: (urlParams: { organizationUserId: string }) =>
        requestBuilder.delete('admin/organizationUser/{organizationUserId}', urlParams),
    },
    responsibility: {
      list: requestBuilder.get('admin/responsibilities'),
      read: (urlParams: { responsibilityId: string }) =>
        requestBuilder.get('admin/responsibility/{responsibilityId}', urlParams),
      update: (urlParams: { responsibilityId: string }) =>
        requestBuilder.put('admin/responsibility/{responsibilityId}', urlParams),
    },
  },
}));
