import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Link, makeStyles, Toolbar, Typography } from '@material-ui/core';

export const usStyles = makeStyles((theme) => ({
  toolbar: {
    gap: theme.spacing(2),
    '& a': {
      color: 'inherit !important',
    },
  },
}));

export default function Navigation() {
  const { t } = useTranslation();
  const classes = usStyles();

  return (
    <AppBar position="static">
      <Toolbar className={classes.toolbar}>
        {[
          ['/admin/organizationUsers', 'admin.OrganizationUser.list.title'],
          ['/admin/organizationUser', 'admin.OrganizationUser.create.title'],
          ['/admin/responsibilities', 'admin.Responsibility.list.title'],
        ].map(([to, key]) => (
          <Typography key={key} variant="h6">
            <Link to={to} component={RouterLink}>
              {t(key)}
            </Link>
          </Typography>
        ))}
      </Toolbar>
    </AppBar>
  );
}
