import React from 'react';
import useFormBuilder from '../../../hooks/useFormBuilder';
import { useAlert } from '../../../hooks/useAlerts';
import { FormBackdrop, FormButtons, FormTitle, useFormStyles } from '../../../components/form';
import { apiEndpoints, useSubmitRequest } from '../../../hooks/useSubmitRequest';
import { useAuth } from '../../../hooks/useAuth';

export default function Login() {
  const formClasses = useFormStyles();
  const {
    TextField,
    handleSubmit,
    formState: { isSubmitting },
  } = useFormBuilder('admin.Login.form');
  const { enqueueAlert } = useAlert();
  const { login } = useAuth();
  const submitRequest = useSubmitRequest();

  const onSubmit = ({ username, password }: { username: string; password: string }) =>
    submitRequest(
      apiEndpoints.login,
      `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
    )
      .then(login)
      .catch(() => enqueueAlert({ messageKey: `admin.Login.failed` }));

  return (
    <div className={formClasses.main}>
      <form id="login" className={formClasses.form} onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormTitle titleKey={`admin.Login.title`} />

        <TextField fieldName="username" required />
        <TextField fieldName="password" type="password" required />

        <FormButtons form={'login'} isSubmitting={isSubmitting} submitKey={`admin.Login.submit`} />
      </form>

      <FormBackdrop isSubmitting={isSubmitting} />
    </div>
  );
}
