import React from 'react';

import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const useStyles = makeStyles((theme) => ({
  remark: {
    color: theme.palette.grey.A700,
    marginTop: theme.spacing(2),
  },
}));

export default function FormRemark({ remarkKey }: { remarkKey: string }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return <span className={classes.remark}>{t(remarkKey)}</span>;
}
