import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';

export const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1,
  },
}));

export default function FormBackdrop({ isSubmitting }: { isSubmitting: boolean }) {
  const classes = useStyles();

  return (
    <Backdrop open={isSubmitting} className={classes.backdrop}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
