import { makeStyles } from '@material-ui/core';

export const useListStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  title: {
    color: theme.palette.primary.main,
  },
  table: {
    minWidth: 650,
    '& tbody > tr': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#d8d8d8',
      },
    },
  },
}));
